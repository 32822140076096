import React, { useEffect } from 'react'
import './navbar-white.css'
import logo from '../../assets/logo-main.png'
import { AiOutlinePlus } from 'react-icons/ai'
import { NavLink, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { AuthContext } from '../../context/authContext'
import { useContext } from 'react'
import MenuIcon from '../HomeComponents/Header/assets/mob/whit-ham.png';
import MenuIconClose from '../HomeComponents/Header/assets/mob/close-ham.png';
import arrow from '../HomeComponents/Header/assets/mob/rightArrow.png';

const Navbar = () => {
    // eslint-disable-next-line
    const [inputs, setInputs] = useState({
        location: 'all'
    })
    const [loggedIn, setLoggedIn] = useState(false)
    const { currentUser } = useContext(AuthContext)
    useEffect(() => {
        if (currentUser) {
            setLoggedIn(true)
        }
    }, [])
    const nav = useNavigate()
    const handleLogout = () => {
        localStorage.removeItem('user')
        window.location.reload();
    }
    const [ToggleMenu, setToggleMenu] = useState(false);
    return (
        <nav className='navbar-white'>
            <div className="left-menu-white">
                <NavLink to="/" className={({ isActive }) => isActive && 'active'}><img src={logo} alt="logo" className='logoCaravinn' /></NavLink>
                <ul>
                    <li>
                        <NavLink style={({ isActive }) => ({
                            color: isActive ? '#0B91C5' : 'black'
                        })} to="/nasil-kiralarim">Nasıl Kiralarım</NavLink>
                    </li>
                    <li>
                        <NavLink style={({ isActive }) => ({
                            color: isActive ? '#0B91C5' : 'black'
                        })} to="/nasil-kiraya-veririm">Nasıl Kiraya Veririm</NavLink>
                    </li>
                    <li>
                        <NavLink style={({ isActive }) => ({
                            color: isActive ? '#0B91C5' : 'black'
                        })} to="/kiralik-karavanlar" state={inputs}>Kiralık Karavanlar</NavLink>
                    </li>
                    <li>
                        <NavLink style={({ isActive }) => ({
                            color: isActive ? '#0B91C5' : 'black'
                        })} to="/blog">Blog</NavLink>
                    </li>
                    <li>
                        <NavLink style={({ isActive }) => ({
                            color: isActive ? '#0B91C5' : 'black'
                        })} to="/about-us">Hakkımızda</NavLink>
                    </li>
                    <li>
                        <NavLink style={({ isActive }) => ({
                            color: isActive ? '#0B91C5' : 'black'
                        })} to="/bize-ulasin">Bize Ulaşın</NavLink>
                    </li>
                </ul>
            </div>
            <div className="right-menu">
                <div className="hamburger-menu">
                    {!ToggleMenu && (
                        <h1 style={{ color: '#FFF' }}><img src={MenuIcon} alt="" style={{ fontSize: '27px' }} onClick={() => setToggleMenu(true)} /></h1>
                    )}
                    {ToggleMenu && (
                        <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
                            {/* <MenuIcon style={{ color: '#FFF' }} className="overlay__close" onClick={() => setToggleMenu(false)} /> */}
                            <img src={MenuIconClose} alt="" style={{ color: '#0b90c5' }} className="overlay__close" onClick={() => setToggleMenu(false)} />
                            <div >
                                {loggedIn ?
                                    <div className="app__navbar-smallscreen_links">
                                        <ul>
                                            <li className='karavanAddButton'>
                                                <NavLink to="/"><img src={logo} alt="" /></NavLink>
                                            </li>
                                            <li className='karavanAddButton'>
                                                <NavLink to="/kiraya-verin"><button>Karavan Ekle</button></NavLink>
                                            </li>


                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/">Anasayfa</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/profile">Profilim</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/kiralik-karavanlar" state={inputs}>Kiralık Karavanlar</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/profile/favorite">Favorilerim</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/profile/adverts">İlanlarım</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/faq">Sıkça Sorulan Sorular</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>

                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/bize-ulasin">Bize Ulaşın</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>



                                        </ul>
                                        <div className='loginButton'>
                                            <button onClick={handleLogout}>Güvenli Çıkış Yap</button>
                                        </div>
                                    </div>
                                    :
                                    <div className="app__navbar-smallscreen_links">
                                        <ul>
                                            <li className='karavanAddButton'>
                                                <NavLink to="/"><img src={logo} alt="" /></NavLink>
                                            </li>
                                            <li className='karavanAddButton'>
                                                <NavLink to="/kiraya-verin"><button>Karavan Ekle</button></NavLink>
                                            </li>

                                            <hr />
                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/nasil-kiralarim">Nasıl Kiralarım?</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                            <hr />
                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/nasil-kiraya-veririm">Nasıl Kiraya Veririm?</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                            <hr />
                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/kiralik-karavanlar" state={inputs}>Kiralık Karavanlar</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                            <hr />
                                            <li>
                                                <div className='fl-ham'>
                                                    <div>
                                                        <NavLink to="/bize-ulasin">Bize Ulaşın</NavLink>
                                                    </div>
                                                    <div>
                                                        <img src={arrow} alt="" />
                                                    </div>
                                                </div>
                                            </li>
                                            <hr />


                                        </ul>
                                        <div className='loginButton'>
                                            <NavLink to="/login"><button>Giriş Yap</button></NavLink>
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    )}
                </div>
                {loggedIn ?
                    <div className="after-logged">
                        <NavLink to="/profile"><button className='btn btn-blue' style={{ fontSize: '14px' }}>Profile</button></NavLink>
                        <button className='btn navbar-white-btn-blue' onClick={handleLogout} style={{ fontSize: '14px' }}>Logout</button>

                    </div>

                    :

                    <div className="before-logged">
                        <NavLink to="/login"><button className="btn navbar-white-btn-blue" style={{ fontSize: '14px' }}>Giriş</button></NavLink>
                        <NavLink to="/kiraya-verin"><button className="btn btn-blue" style={{ fontSize: '14px', display: 'flex', alignItems: 'center' }}><AiOutlinePlus style={{ marginRight: 5, fontSize: '1.2rem' }} /> Karavan Ekle</button></NavLink>
                    </div>
                }
            </div>
        </nav>

    )
}

export default Navbar
