import React from 'react'
import './banner.css'
import { FiMail } from 'react-icons/fi';
import { IoCallOutline, IoLocationOutline } from 'react-icons/io5';
import Logo from './logo.svg'


const Banner = () => {
    return (
        <div className='banner'>
            <div className="flex-1">
                <div className="part">
                    <ul>
                        <a target="_blank" rel="noopener noreferrer" href='mailto:info@caravinn.com'>
                            <li>
                                <FiMail
                                    style={{
                                        marginRight: 10,
                                        fontSize: 18
                                    }}
                                />
                                info@caravinn.com
                            </li>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='tel:+902129094062'>
                            <li>
                                <IoCallOutline style={{ marginRight: 10, fontSize: 18 }} />
                                +0 (212) 909 40 62
                            </li>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href='http://maps.google.com/?q=Esentepe Mah. Talatpaşa Cad. No:5, Şişli, İstanbul'>
                            <li>
                                <IoLocationOutline style={{ marginRight: 10, fontSize: 18 }} />
                                Esentepe Mah. Talatpaşa Cad. No:5, Şişli, İstanbul
                            </li>
                        </a>
                    </ul>
                </div>
            </div>
            <div className="flex-2">
                <img src={Logo} alt="logo" className='logo' />
            </div>
        </div>
    )
}

export default Banner