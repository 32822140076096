import React from 'react'
import './blog.css'
import BlogCard from '../BlogCard/BlogCard'
import { Link } from 'react-router-dom';



const Blog = () => {


    return (
        <div
            className='blog'
        >
            <h1>Caravınn</h1>
            <h1>Blog</h1>

            <div className="blog-cards">
                <BlogCard limit={3} />
            </div>
            <Link to={'/blog'}>
                <button className='blog-all-btn'>Tümünü Gör</button>
            </Link>

        </div>
    )
}

export default Blog
