import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import './blogcard.css'

const BlogCard = (props) => {

  const [blogList, SetblogList] = useState([]);
  const goUp = () => {
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    // eslint-disable-next-line
    axios.get(`${process.env.REACT_APP_OTHER_OTHER_THING}/api/blog/post`).then((response) => {
      SetblogList(response.data)
    });

  }, []);

  return (
    <div className='mob-blog-sugg'>
      {blogList.length === 0 ? (
        <div style={{ margin: '50px' }}>
          <h1>BLOG YOK</h1>
        </div>
      ) : (
        blogList.slice(0, props.limit).map((val, key) => {
          return (
            <NavLink key={key} className="blog_link" onClick={goUp}  to={`/blog/${val.id}`} style={{ cursor: 'pointer' }}>
              <img src={`/img/${val.blog_image}`} alt="alt" />
              <p style={{ textAlign: 'left' }}>{val.blog_title}</p>
            </NavLink>
          );
        })
      )
      }
    </div>
  )
}




export default BlogCard
