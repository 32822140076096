import React, { useEffect } from 'react'
import "./caravan.css"

import EditRoadOutlinedIcon from '@mui/icons-material/EditRoadOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import RvHookupOutlinedIcon from '@mui/icons-material/RvHookupOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CurrencyLiraOutlinedIcon from '@mui/icons-material/CurrencyLiraOutlined';
import { Link, useParams } from 'react-router-dom'
import { useState } from 'react'
import { ImageViewer } from "react-image-viewer-dv"
import ImageSlider from './ImageSlider/ImageSlider';





const Caravan = () => {



    const { id } = useParams()
    const [caravan, SetCaravan] = useState([]);
    const [file, setFiles] = useState()
    const [img, setImage] = useState({});

    useEffect(() => {
        const FetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_OTHER_OTHER_THING}/api/caravan/${id}`);
            const data = await response.json();
            SetCaravan(data)
            setFiles(data[0].images)
            if (file) {
                var urls = file;
                var array = urls.split(',');
                setImage(array)
            }
        }
        FetchData()
    }, [file, id])


    const slides = [
        { url: img[0] ? `/img/${img[0]}` : `/img/error.png`, title: "beach" },
        { url: img[1] ? `/img/${img[1]}` : `/img/error.png`, title: "boat" },
        { url: img[2] ? `/img/${img[2]}` : `/img/error.png`, title: "forest" },
        { url: img[3] ? `/img/${img[3]}` : `/img/error.png`, title: "city" },
        { url: img[4] ? `/img/${img[4]}` : `/img/error.png`, title: "italy" },
    ];

    return (
        <div style={{ padding: '50px 10%' }}>
            {caravan === [] ? (
                <h1>Loading...</h1>
            ) : (
                caravan.map((val, key) => {
                    return (

                        <div className='caravan-details-header' key={key} >
                            <div className="caravan-images">

                                <div >
                                    <div className='slider-class' >
                                        <ImageSlider slides={slides} />
                                    </div>
                                </div>
                                <div className="small-images">
                                    <ImageViewer>
                                        <img
                                            src={img[1] ? `/img/${img[1]}` : `/img/error.png`}
                                            style={{ borderTopRightRadius: 15 }}
                                            alt=""
                                        />
                                    </ImageViewer>
                                    <ImageViewer>
                                        <img
                                            src={img[2] ? `/img/${img[2]}` : `/img/error.png`}
                                            alt=""
                                        />
                                    </ImageViewer>
                                    <ImageViewer>
                                        <img
                                            src={img[3] ? `/img/${img[3]}` : `/img/error.png`}
                                            alt=""
                                        />
                                    </ImageViewer>
                                    <ImageViewer>
                                        <img
                                            src={img[4] ? `/img/${img[4]}` : `/img/error.png`}
                                            alt=""
                                            style={{ borderBottomRightRadius: 15 }}
                                        />
                                    </ImageViewer>
                                </div>

                            </div>
                            <div className="caravan-details">
                                <h1>{val.caravan_title}</h1>
                                <div className="caravan-feauters">
                                    <span><EditRoadOutlinedIcon className='icon-caravan' />{val.road}</span>
                                    <span><LocalGasStationOutlinedIcon className='icon-caravan' />{val.fuel_type}</span>
                                    <span><RvHookupOutlinedIcon className='icon-caravan' />{val.caravan_type}</span>
                                    <span><LocationOnOutlinedIcon className='icon-caravan' />{val.location}</span>
                                    <span><CurrencyLiraOutlinedIcon className='icon-caravan' />{val.price}</span>
                                    <button style={{ backgroundColor: '#1E98C7', marginTop: 25 }}><Link style={{ color: '#FFF' }} to={`/caravan-rezerve/${id}`}>Hemen Rezervasyon Oluştur</Link></button>
                                    {/* {user_id ?
                                        (<div>
                                            {
                                                liked ?
                                                    <button style={{ backgroundColor: '#FFF', color: "#1E98C7" }} onClick={insertLike}>Favoriden Çıkar</button>
                                                    :
                                                    <button style={{ backgroundColor: '#FFF', color: "#1E98C7" }} onClick={insertLike}>Favoriye Ekle</button>
                                            }
                                        </div>)
                                        :
                                        (<div>

                                        </div>)
                                    }
 */}

                                </div>
                            </div>
                        </div>
                    )
                })
            )}

            <div className="description">
                <button style={{ backgroundColor: '#1E98C7', color: '#FFF', marginTop: 25 }}>Açıklama</button>
                <button style={{ marginLeft: 15, backgroundColor: '#FFF', color: "#1E98C7" }}>Ektsra Hizmetler</button>
                <hr />
                <p>Çerez, kabul etmeniz halinde tarayıcınızda veya bilgisayarınızın sabit diskinde sakladığımız
                    harf ve rakamlardan oluşan küçük bir dosyadır.
                    Çerezler, bilgisayarınızın sabit diskine aktarılan bilgileri içerir. Çerez, kabul etmeniz
                    halinde tarayıcınızda veya bilgisayarınızın sabit diskinde sakladığımız harf ve rakamlardan
                    oluşan küçük bir dosyadır. Çerezler, bilgisayarınızın sabit diskine aktarılan bilgileri
                    içerir. Çerez, kabul etmeniz halinde tarayıcınızda veya bilgisayarınızın sabit diskinde
                    sakladığımız harf ve rakamlardan oluşan küçük bir dosyadır. Çerezler, bilgisayarınızın
                    sabit diskine aktarılan bilgileri içerir. Çerez, kabul etmeniz halinde tarayıcınızda veya
                    bilgisayarınızın sabit diskinde sakladığımız harf ve rakamlardan oluşan küçük bir dosyadır.
                    Çerezler, bilgisayarınızın sabit diskine aktarılan bilgileri içerir. Çerez, kabul etmeniz
                    halinde tarayıcınızda veya bilgisayarınızın sabit diskinde sakladığımız harf ve rakamlardan
                    oluşan küçük bir dosyadır. Çerezler, bilgisayarınızın sabit diskine aktarılan bilgileri
                    içerir. Çerez, kabul etmeniz halinde tarayıcınızda veya bilgisayarınızın sabit diskinde
                    sakladığımız harf ve rakamlardan oluşan küçük bir dosyadır. Çerezler
                    , bilgisayarınızın sabit diskine aktarılan bilgileri içerir. </p>
                <hr />
            </div>

        </div >
    )
}

export default Caravan
