import React, { useState, useEffect } from 'react'
import './style.css'
import road from '../../../assets/road.svg'
import benzin from '../../../assets/benzin.svg'
import cara from '../../../assets/cara.svg'
import axios from 'axios'
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import { useContext } from 'react'
import { AuthContext } from '../../../context/authContext'
const FavCard = (props) => {

    const [likeData, setLikeData] = useState({});
    const id = props.cardid;
    const { currentUser } = useContext(AuthContext)
    const [img, setImage] = useState({});
    const [liked, SetLiked] = useState(false)
    useEffect(() => {
        if (props.img) {
            var urls = props.img;
            var array = urls.split(',');
            setImage(array)
        }
        // axios.get(`${process.env.REACT_APP_OTHER_OTHER_THING}/api/like/${id}/${currentUser.id}`).then((response) => {
        //     setLikeData(response.data)
        //     if (likeData.length > 0) {
        //         SetLiked(true)
        //     } else {
        //         SetLiked(false)
        //     }
        // })
        const config = {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
            },
        };
        axios.get(`${process.env.REACT_APP_OTHER_OTHER_THING}/api/like/${id}/${currentUser.id}`, config).then((response) => {
            setLikeData(response.data)
            if (likeData.length > 0) {
                SetLiked(true)
            } else {
                SetLiked(false)
            }
        })
    }, [likeData])



    const insertLike = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_OTHER_OTHER_THING}/api/like/likeInsert/${id}/${currentUser.id}`).then((response) => {
               

            })
        }
        catch (err) {
           
        }
    }
    return (
        <div className="fav-card">

            <div className="like-button" onClick={insertLike}>
                {liked ? <FavoriteOutlinedIcon /> : <FavoriteTwoToneIcon style={{ color: '#fff' }} />}
            </div>
            <img src={`/img/${img[0]}`} style={{ borderRadius: 15, width: '330px', height: '220px' }} alt='' />
            <h3>{props.title}</h3>

            <hr />

            <div className="location">
                <i className="fa-solid fa-location-dot"></i>
                <p>{props.location}</p>
            </div>
            <hr />
            <div className="icons">
                <div className="icon">
                    <img src={road} alt="" />
                    <p>{props.road}</p>
                </div>
                <div className="icon">
                    <img src={benzin} alt="" />
                    <p>{props.fuel}</p>
                </div>
                <div className="icon">
                    <img src={cara} alt="" />
                    <p>{props.type}</p>
                </div>
            </div>
        </div>
    )
}

export default FavCard
