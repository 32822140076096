import React from 'react'
import './blogdetailswrapper.css'
import logo from './logo.png'
import LoginFirstComp from '../LoginFirstComp/LoginFirstComp';
import CommentComp from '../CommentComp/CommentComp';
import CommentsComp from '../CommentsComp/CommentsComp';
import BlogCard from '../../../HomeComponents/BlogCard/BlogCard';



const BlogDetailsWrapper = (props) => {
    const user = JSON.parse(localStorage.getItem('user'))
    return (
        <div className="blog-details-wrapper" >
            <h1>{props.header}</h1>
            <div style={{ padding: '0 15%', marginTop: '50px', textAlign: 'left', whiteSpace: 'pre-line', marginBottom: 30 }}>
                <span>{props.body}</span>
            </div>
            <br />
            <div className="logo-caravinn">
                <img src={logo} alt="" />
                <div className="logo-title">
                    <h3>Caravınn</h3>
                    <p>Caravınn Ailesi</p>
                </div>
            </div>

            {user ?
                (<CommentComp />)
                :
                (<LoginFirstComp />)
            }
            <CommentsComp />
            <h1 style={{ width: '100%', color: '#196CA5', fontSize: '2.5rem', textAlign: 'center' }}>
                Diğer Yazılar
            </h1>

            <BlogCard limit={3} />


        </div>
    )
}

export default BlogDetailsWrapper