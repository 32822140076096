import React, { useEffect, useState } from 'react'
import Header from '../../components/CaravansComponents/Header/Header'
import Navbar from '../../components/NavBar/NavbarWhite'
import CaravansCard from '../../components/CaravansComponents/CaravansCard/CaravansCard'
import './caravans.css'
import { useLocation } from 'react-router-dom';

const Caravans = () => {
  const location = useLocation();
  const data = location.state;
  const dt = data.label;
  useEffect(() => {
    window.scrollTo(0, 0)
    if (dt) {
      setStatusFilter(dt)
    } else {
      setStatusFilter('all')
    }
  }, [location.state])


  const [buttonColors, setButtonColors] = useState({
    color1: "btn btn-blue",
    color2: "btn btn-transparent-blue",
    color3: "btn btn-transparent-blue",
    color4: "btn btn-transparent-blue",
  });
  const [statusFilter, setStatusFilter] = useState('all');

  return (
    <div className='test-2341'>
      <Navbar />
      <Header />
      <div className="wrapper-white" style={{ bottom: 120, padding: '75px 10%' }}>
        <div className="caravans-buttons" style={{ marginBottom: '25px' }}>
          <button
            onClick={() => {
              setButtonColors({
                color1: "btn btn-blue",
                color2: "btn btn-transparent-blue",
                color3: "btn btn-transparent-blue",
                color4: "btn btn-transparent-blue"
              })
              setStatusFilter('all')
            }}
            className={buttonColors.color1}>
            Tümü
          </button>
          <button
            onClick={() => {
              setButtonColors({
                color2: "btn btn-blue",
                color1: "btn btn-transparent-blue",
                color3: "btn btn-transparent-blue",
                color4: "btn btn-transparent-blue"
              })
              setStatusFilter('moto')
            }}
            className={buttonColors.color2}>
            Moto Karavan
          </button>
          <button
            onClick={() => {
              setButtonColors({
                color3: "btn btn-blue",
                color2: "btn btn-transparent-blue",
                color1: "btn btn-transparent-blue",
                color4: "btn btn-transparent-blue"
              })
              setStatusFilter('cekme')
            }}
            className={buttonColors.color3}>
            Çekme Karavan
          </button>
          <button
            onClick={() => {
              setButtonColors({
                color3: "btn btn-transparent-blue",
                color2: "btn btn-transparent-blue",
                color1: "btn btn-transparent-blue",
                color4: "btn btn-blue"
              })
              setStatusFilter('sabit')
            }}
            className={buttonColors.color4}>
            Sabit Karavan
          </button>

        </div>
        <CaravansCard limit="24" request={statusFilter} />
      </div>
    </div>
  )
}

export default Caravans