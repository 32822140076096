import React, { useState, useEffect } from 'react'
import './header.css'
import bg from './assets/bg.svg'
import bg1 from './assets/mob/cloud-up.png'
import bg2 from './assets/mob/cloud-down.png'
import Navbar from '../../NavBar/Navbar'
import { Link } from 'react-router-dom'
// import dateIcon from './assets/dateicon.svg'
// import moment from 'moment'
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment from 'moment/moment';
import Select from 'react-select';
import axios from 'axios';
const MainHeader = () => {
  const [options, SetCardList] = useState([]);
  const [options2, SetCardList2] = useState([]);


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_OTHER_OTHER_THING}/api/caravan/lc`).then((response) => {
      SetCardList(response.data);
      let data = [];
      options.map((val, key) => {
        data.push({
          value: val.location,
          label: val.location
        })
      })
      SetCardList2(data);
    })

  }, [options2])
  const [inputs, setInputs] = useState({
    location: ""
  })

  const handleChange = (e) => {
    setInputs(e.target.value);

  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [value, setValue] = React.useState(null);
  const [value1, setValue1] = React.useState(null);
  const [value2, setValue2] = React.useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      backgroundColor: state.isSelected ? '#0DA2C9' : 'inherit',
      color: state.isSelected ? '#FFF' : '#000',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '100%',
      display: 'flex',
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    }
  }
  let newDate = new Date();
  // eslint-disable-next-line
  const [date, setDate] = useState(moment(newDate).format('MM/DD/YYYY'))
  return (
    <div className='home-header'>
      <div style={{
        width: '100%',
        height: '30px',
        backgroundColor: '#FFF',
        zIndex: 4,
        position: 'absolute',
        bottom: 0,
        left: 0,
        borderRadius: '36px 36px 0 0'
      }}></div>
      <Navbar className="navbar" />
      <div className="title-sec">
        <img style={{ opacity: '85%' }} src={bg} alt="" className='title-sec-bg' />
        <img style={{ opacity: '85%' }} src={bg1} alt="" className='title-sec-bg1 up' />
        <img style={{ opacity: '85%' }} src={bg2} alt="" className='title-sec-bg1 down' />
        <div className="te">
          <h1>Maceraya Vınn'lıyoruz..</h1>
          <div className='inputs-desktop' style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
            <div className="buttons">
              <Link to={'/kiralik-karavanlar'}><button className="btn btn-white" style={{ padding: '7px 40px' }}>Karavan Kirala</button></Link>
              <Link to={'/kiraya-verin'}><button className="btn btn-transparent" style={{ padding: '7px 40px' }}>Karavan kiraya ver</button></Link>
            </div>
            <div className="searchBar">
              {/* <input id="mainInput" onChange={handleChange} name="location" type="text" placeholder="" /> */}
              {/* <select onChange={handleChange} id="select-cont">
                <option name="location" value="" disabled selected hidden>Nereden almak istersen?</option>
                <option style={{color: '#000', backgroundColor: '#FFF', padding: 10}} value="Istanbul">Istanbul</option>
              </select> */}
              <Select
                menuPlacement="top"
                placeholder={<div>Nereden almak istersen?</div>}
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options2}
                styles={customStyles}
              />
              <span id="line" style={{ margin: '10px' }}></span>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        placeholder: date
                      }}
                    />
                  )}
                />
                <span id="line" style={{ margin: '10px' }}></span>
                <MobileDatePicker

                  value={value1}
                  onChange={(newValue1) => {
                    setValue1(newValue1);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        placeholder: date

                      }}
                    />
                  )}
                />
              </LocalizationProvider>


              <div className="button vinn">
                <Link to={`/kiralik-karavanlar`} state={selectedOption}><button className="btn btn-blue-big">Vınn'la</button></Link>
              </div>
            </div>
          </div>
          <div className='inputs-mobile'>
            <div className="buttons">
              <Link to={'/kiralik-karavanlar'}><button className="btn btn-white" style={{ padding: '7px 40px' }}>Karavan Kirala</button></Link>
              <Link to={'/kiraya-verin'}><button className="btn btn-transparent" style={{ padding: '7px 40px' }}>Karavan kiraya ver</button></Link>
            </div>
            <div className="searchBar">
              <div id="mainInput" style={{ backgroundColor: '#fff', width: '100%', borderRadius: 15 }}>
                {/* <select onChange={handleChange}>
                  <option name="location" value="" disabled selected hidden>Nereden almak istersen?</option>
                  <option value="Istanbul">Istanbul</option>
                </select> */}
                <Select
                  menuPlacement="top"
                  placeholder={<div>Nereden almak istersen?</div>}
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options2}
                  styles={customStyles}
                />
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ backgroundColor: '#FFF', display: 'flex', alignContent: 'center', justifyContent: 'center', width: '100%', padding: 8, borderRadius: 25, margin: 10 }}>
                  <MobileDatePicker

                    value={value1}
                    onChange={(newValue1) => {
                      setValue1(newValue1);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} id="outlined-basic" variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          placeholder: date
                        }}
                        fullWidth
                      />
                    )}
                  />
                </div>

                <div style={{ backgroundColor: '#FFF', display: 'flex', alignContent: 'center', justifyContent: 'center', width: '100%', padding: 8, borderRadius: 25, margin: 10 }}>
                  <MobileDatePicker

                    value={value2}
                    onChange={(newValue2) => {
                      setValue2(newValue2);
                    }}
                    renderInput={(params1) => (
                      <TextField {...params1} id="outlined-basic" variant="standard"
                        InputProps={{
                          disableUnderline: true,
                          placeholder: date
                        }}
                        fullWidth
                      />
                    )}
                  />

                </div>
              </LocalizationProvider>



              <div className="button vinn" style={{ marginTop: 15 }}>
                <Link to={`/kiralik-karavanlar`} state={selectedOption}><button className="btn btn-blue-big">Vınn'la</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainHeader